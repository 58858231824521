@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("./assets/fonts/Inter-Thin.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-Thin.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("./assets/fonts/Inter-ThinItalic.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-ThinItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("./assets/fonts/Inter-ExtraLight.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-ExtraLight.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("./assets/fonts/Inter-ExtraLightItalic.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-ExtraLightItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/Inter-Light.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-Light.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/Inter-LightItalic.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-LightItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/Inter-Regular.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-Regular.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/Inter-Italic.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-Italic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/fonts/Inter-Medium.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-Medium.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/fonts/Inter-MediumItalic.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-MediumItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./assets/fonts/Inter-SemiBold.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-SemiBold.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("./assets/fonts/Inter-SemiBoldItalic.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-SemiBoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./assets/fonts/Inter-Bold.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-Bold.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("./assets/fonts/Inter-BoldItalic.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-BoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("./assets/fonts/Inter-ExtraBold.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-ExtraBold.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("./assets/fonts/Inter-ExtraBoldItalic.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-ExtraBoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./assets/fonts/Inter-Black.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-Black.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("./assets/fonts/Inter-BlackItalic.woff2?v=3.13") format("woff2"),
  url("./assets/fonts/Inter-BlackItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url("./assets/fonts/Inter-roman.var.woff2?v=3.13") format("woff2");
}

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url("./assets/fonts/Inter-italic.var.woff2?v=3.13") format("woff2");
}

/* You can add global styles to this file, and also import other style files */
* {
  font-size: 100%;
  font-family: 'Inter';
}

html, body {
  height: 100%;
}

.wrapper {
  min-height: 100%;
  margin-bottom: -100px;
  padding-bottom: 100px;
}

footer {
  height: 10rem;
}

.modal-content {
  width: 95%;
}

.modal-header .close {
  padding: .4rem 0.6rem;
  margin: -10px -10px -1rem auto;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .modal-dialog.modal-dialog-centered {
    margin: unset !important;
    height: 100% !important;
    width: 100% !important;
    justify-content: center !important;
  }

  .modal-dialog.modal-dialog-centered > .modal-content {
    width: 100% !important;
    height: 100% !important;
  }

  .fit.modal {
    width: 95% !important;
    height: auto !important;
    margin: 50% auto;
    left: 0;
    right: 0;
  }

  .modal.show .modal-dialog {
    float: right;
  }
}
